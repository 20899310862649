import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { FONT } from '@constants/style';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      '& > button': {
        backgroundColor: 'transparent',
        height: '40px',
        width: '100%',
        '& > p': {
          fontWeight: FONT.WEIGHT.MEDIUM,
        },
        '&:hover': {
          color: theme.palette.hover.contrastText,
          backgroundColor: 'transparent',  
        },
      },
    },
  }),
);

import { FONT, QUICK_SEARCH } from '@constants/style';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginLeft: theme.spacing(4),
    height: theme.spacing(5),
    flex: 1,
    backgroundColor: '#F8F9FB',
    display: 'flex',
    position:'relative',
  },
  searchInput: {
    padding: '16px 10px',
    flex: 1,
  },
  searchIconButton: {
    padding: 10,
  },
  searchHistoryRoot: {
    zIndex: 1,
    width: '100%',
    backgroundColor: 'white',
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    padding: 0,
    position: 'absolute',
    top: '40px',
    margin: 0,
  },
  searchHistoryLabel: {
    padding: '12px 0px 8px 21px',
    width: '100%',
    lineHeight: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    color: QUICK_SEARCH.COLOR.FONT.MAIN_DARK,
    cursor: 'default',
  },
  searchKeywordItem: {
    width: '100%',
    padding: '12px 24px 12px 24px',
    '&:hover': {
      backgroundColor: QUICK_SEARCH.COLOR.BACKGROUND.LIGHT,
      color: QUICK_SEARCH.COLOR.FONT.DARK,
    },
  },
  searchRecords: {
    lineHeight: '16px',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 400,
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
  },
  clearSearchButton: {
    padding: '12px 0',
    width: '100%',
    lineHeight: '16px',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    color: QUICK_SEARCH.COLOR.FONT.DARK,
    '&:hover': {
      backgroundColor: QUICK_SEARCH.COLOR.BACKGROUND.LIGHT,
    },
  },
}));
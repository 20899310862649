import { APPBAR } from '@constants/style';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '175px',
    height: `${APPBAR.HEIGHT}px`,
    borderLeft: '1px solid #e2e2e2',
    fontSize: '16px',
    lineHeight: '18px',
    paddingLeft: '24px',
    paddingRight: '24px',
    borderRadius: 0,
    textTransform: 'capitalize',
    color: '#404040',
    '&:hover': {
      backgroundColor: theme.palette.hover.main,
    },
    '& > span > span > span': {
      background: '#f50057',
    },
  },
  onlyIconWrapper: {
    '& > button': {
      width: '73px',
    }
  },
  popper: {
    width: '175px',
    borderRadius: 0,
    zIndex: '20',
  },
}));

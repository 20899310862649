import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '../props/common/svgicon.props';

export interface IBookmarkOutlinedIconProps extends ISvgIconProps {
  className?:string
}

const BookOutlineIcon = (props: IBookmarkOutlinedIconProps) => {
  const { color = '#404040', className } = props;


  return (
    <SvgIcon 
      viewBox="0 0 25 24"
      fill="none"
      width="25"
      height="24"
      htmlColor={color} 
      classes={{
        root: className,
      }}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M19.3401 2.92004V3.99892H18.2633V5.07888H19.3401V20.1908H6.41803C6.41803 20.1908 5.34119 20.1908 5.34119 19.1113V4.54889L5.34093 4.54403L5.34066 4.53917C5.32181 3.80787 5.77516 3.29029 6.1779 3.1111C6.54685 2.92969 6.86289 2.92231 6.94238 2.92045L6.95645 2.92004H19.3401ZM6.9543 5.07888H6.95645H17.1864V3.99892H6.95645C6.95604 4.00017 6.95341 4.0003 6.94676 4.00063C6.93608 4.00115 6.91505 4.00219 6.87623 4.00918C6.81431 4.01943 6.7287 4.04156 6.65871 4.07664C6.6458 4.08538 6.63335 4.09349 6.62135 4.10129L6.62135 4.10129C6.50668 4.17587 6.43412 4.22306 6.41803 4.53917C6.426 4.80548 6.4885 4.86627 6.56155 4.93733L6.56448 4.94018C6.64201 5.00818 6.78039 5.0546 6.87623 5.06917C6.92738 5.07834 6.94784 5.07888 6.9543 5.07888ZM18.3194 19.1441H6.41803V5.89446H18.3194V19.1441Z" fill="#404040" />
      <mask id="mask0_1243_2350" maskUnits="userSpaceOnUse" x="5" y="2" width="15" height="19">
        <path fillRule="evenodd" clipRule="evenodd" d="M19.3401 2.92004V3.99892H18.2633V5.07888H19.3401V20.1908H6.41803C6.41803 20.1908 5.34119 20.1908 5.34119 19.1113V4.54889L5.34093 4.54403L5.34066 4.53917C5.32181 3.80787 5.77516 3.29029 6.1779 3.1111C6.54685 2.92969 6.86289 2.92231 6.94238 2.92045L6.95645 2.92004H19.3401ZM6.9543 5.07888H6.95645H17.1864V3.99892H6.95645C6.95604 4.00017 6.95341 4.0003 6.94676 4.00063C6.93608 4.00115 6.91505 4.00219 6.87623 4.00918C6.81431 4.01943 6.7287 4.04156 6.65871 4.07664C6.6458 4.08538 6.63335 4.09349 6.62135 4.10129L6.62135 4.10129C6.50668 4.17587 6.43412 4.22306 6.41803 4.53917C6.426 4.80548 6.4885 4.86627 6.56155 4.93733L6.56448 4.94018C6.64201 5.00818 6.78039 5.0546 6.87623 5.06917C6.92738 5.07834 6.94784 5.07888 6.9543 5.07888ZM18.3194 19.1441H6.41803V5.89446H18.3194V19.1441Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1243_2350)">
        <rect x="0.340088" y="-0.0799561" width="24" height="24" fill="#404040" />
        <mask id="mask1_1243_2350" maskUnits="userSpaceOnUse" x="0" y="-1" width="25" height="25">
          <rect x="0.340088" y="-0.0799561" width="24" height="24" fill="white" />
        </mask>
        <g mask="url(#mask1_1243_2350)" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.84009 9.89478H16.5401V10.9061H8.84009V9.89478Z" fill="#404040" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.84009 11.6384H16.5401V12.6498H8.84009V11.6384Z" fill="#404040" />
      <rect x="8.84009" y="13.3821" width="5.6" height="1.01133" fill="#404040" />
    </SvgIcon>
  );
};


export default BookOutlineIcon;

import { defineMessages } from 'react-intl';

export const scope = 'src.react.components.menu.profile';

export default defineMessages({
  alertSettings: {
    id: `${scope}.alertSettings`,
    defaultMessage: 'Alert Settings',
  },
  myAccount: {
    id: `${scope}.myAccount`,
    defaultMessage: 'My Account',
  },
  faqs: {
    id: `${scope}.faqs`,
    defaultMessage: 'FAQs',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Logout',
  },
  upgrade: {
    id: `${scope}.upgrade`,
    defaultMessage: 'Upgrade',
  },
  changeRole: {
    id: `${scope}.role`,
    defaultMessage: 'Change to {role}',
  },
});
import React, { useState, useRef } from 'react';
import { ButtonBase, ClickAwayListener, IconButton, InputBase, MenuItem, MenuList,  Typography } from '@mui/material';
import { EMPTY_STRING, OBJECT_NAME, SEARCH_NAVBAR_TAB, UI_ELEMENT, UI_ELEMENT_GROUP } from '@constants/common';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { resetFilters } from '@actions/filters/filterBar.actions';
import { updateSearchKeyword } from '@redux/actions/quickSearch.actions';
import { useHistory } from 'react-router';
import AtlasDataTrackable from '@react/components/atlas/atlas.component';
import { ROUTE_PATHS } from '@constants/routes';
import { isEmpty } from 'lodash';
import { cleanLocalStorageMostRecentSearch, getLocalStorageMostRecentSearch, saveLocalStorageMostRecentSearch } from '@react/utils/common.util';
import SearchIcon from '../icons/searchIcon.component';
import messages from './messages';
import { useStyles } from './style';
import { constructAtlasTrackingObj } from '../company/companyDetail/relationships/table/trackData';

interface ISearchHistoryObject {
  keyword: string
}

const SearchOutlineInput = () => {
  const intl = useIntl();
  const classes = useStyles();

  const MAX_SEARCH_HISTORY_SIZE = 5;

  const [searchValue, setSearchValue] = useState(EMPTY_STRING);
  const [mostRecentSearches, setMostRecentSearches] = useState<ISearchHistoryObject[]>(getLocalStorageMostRecentSearch());
  const [openMenu, setOpenMenu] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const submitRef = useRef(null);

  const searchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const removeDuplicateSearchHistory = (arr, keyword) => {
    return arr.filter((searchHistory) => {
      return (searchHistory.keyword !== keyword);
    });
  };

  const processMostRecentSearchesArray =
  (keyword, recentSearchArray: ISearchHistoryObject[]) => {
    const mostRecentSearchesArrayProcessed =
      removeDuplicateSearchHistory(recentSearchArray, keyword);

    mostRecentSearchesArrayProcessed.unshift({ keyword });
    if (mostRecentSearchesArrayProcessed.length > MAX_SEARCH_HISTORY_SIZE) {
      return mostRecentSearchesArrayProcessed.slice(0, MAX_SEARCH_HISTORY_SIZE);
    }
    return mostRecentSearchesArrayProcessed;
  };

  const updateMostRecentSearches = (keyword: string) => {
    const mostRecentSearchTempArray = mostRecentSearches;
    if (!isEmpty(keyword)) {
      const checkedMostRecentSearchesArray =
        processMostRecentSearchesArray(
          keyword,
          mostRecentSearchTempArray) as [];
      setMostRecentSearches(checkedMostRecentSearchesArray);
      saveLocalStorageMostRecentSearch(checkedMostRecentSearchesArray);
    }
  };

  const searchInputEnter =
  (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      submitRef.current.click();
    }
  };

  const searchKeyword = (keywordValue?: string) => {
    dispatch(resetFilters());
    const keyword = keywordValue || searchValue;
    dispatch(updateSearchKeyword(keyword));
    const queryObj = {
      data: {
        searchStr: keyword,
      }
    };
    updateMostRecentSearches(keyword.trim());
    setOpenMenu(false);
    history.push(ROUTE_PATHS.SEARCH_ALL, { newsQuery: queryObj, companyQuery: queryObj });

    // next render process start init searchValue
    setTimeout(() => {
      setSearchValue(EMPTY_STRING);
    }, 0);
  };

  const clearMostRecentSearches = () => {
    cleanLocalStorageMostRecentSearch();
    setMostRecentSearches([]);
  };

  return (
    <ClickAwayListener
      onClickAway={() => { setOpenMenu(false); }}
    >
      <Typography component="div" className={classes.wrapper}>
        <InputBase
          className={classes.searchInput}
          placeholder={intl.formatMessage(messages.searchDropdownStylePlaceholder)}
          value={searchValue}
          defaultValue={EMPTY_STRING}
          onKeyDown={searchInputEnter}
          onChange={searchInputChange}
          onClick={() => setOpenMenu(true)}
        />
        <IconButton
          ref={submitRef}
          type="submit"
          className={classes.searchIconButton}
          onClick={() => searchKeyword()}
          size="small"
          {...AtlasDataTrackable({
            targetAttribute: 'search-icon-button',
            atlastracking: constructAtlasTrackingObj(
              UI_ELEMENT_GROUP.QUICK_SEARCH_BAR_HOMEPAGE,
              UI_ELEMENT.QUICK_SEARCH_BAR,
              OBJECT_NAME.SEARCH_BUTTON,
              searchValue, 
              SEARCH_NAVBAR_TAB.ALL,
            ),
          })}
        >
          <SearchIcon color="#878787" fontSize="small" />
        </IconButton>
    
        <>
          {(!isEmpty(mostRecentSearches) && openMenu) &&
          <MenuList
            className={classes.searchHistoryRoot}
          >
            <MenuItem
              className={classes.searchHistoryLabel}
            >
              {intl.formatMessage(messages.recentSearches)}
            </MenuItem>
            {
                  mostRecentSearches.map((item) => {
                    return (
                      <MenuItem
                        key={`${item.keyword}`}
                        className={classes.searchKeywordItem}
                        onClick={() => searchKeyword(item.keyword)}
                        component='button'
                        {...AtlasDataTrackable({
                          targetAttribute: 'most-recent-search-menu-item',
                          atlastracking: constructAtlasTrackingObj(
                            UI_ELEMENT_GROUP.QUICK_SEARCH_BAR_HOMEPAGE,
                            UI_ELEMENT.QUICK_SEARCH_BAR,
                            OBJECT_NAME.RECENT_SEARCHES,
                            item.keyword,
                            SEARCH_NAVBAR_TAB.ALL,
                          ),
                        })}
                      >
                        <span
                          className={classes.searchRecords}
                        >{item.keyword}
                        </span>
                      </MenuItem>
                    );
                  })
                }
            <ButtonBase
              className={classes.clearSearchButton}
              onClick={clearMostRecentSearches}
              {...AtlasDataTrackable({
                targetAttribute: 'clear-recent-searches-button',
                atlastracking: {
                  uiElementGroup: UI_ELEMENT_GROUP.QUICK_SEARCH_BAR_HOMEPAGE,
                  uiElement: UI_ELEMENT.QUICK_SEARCH_BAR,
                  uiElementDetails: SEARCH_NAVBAR_TAB.ALL,
                  objectName: OBJECT_NAME.CLEAR_RECENT_SEARCHES,
                }
              })}
            >
              {intl.formatMessage(messages.clearSearch)}
            </ButtonBase>
          </MenuList>}
        </>
      </Typography>
    </ClickAwayListener>
  );
};

export default SearchOutlineInput;
import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '../props/common/svgicon.props';

export interface IIconProps extends ISvgIconProps {
  className?: string;
}

const AccountIcon = (props: IIconProps) => {
  const { className } = props;
  const svg = (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 4C10.1807 4 8.3 5.8807 8.3 8.2C8.3 10.6205 10.2427 13.1 12.5 13.1C14.7573 13.1 16.7 10.6205 16.7 8.2C16.7 5.8807 14.8193 4 12.5 4ZM9.7 8.2C9.7 9.91186 11.1011 11.7 12.5 11.7C13.8989 11.7 15.3 9.91186 15.3 8.2C15.3 6.6539 14.0461 5.4 12.5 5.4C10.9539 5.4 9.7 6.6539 9.7 8.2ZM19.5 18.7C19.5 15.9936 17.3064 13.8 14.6 13.8H10.4C7.6936 13.8 5.5 15.9936 5.5 18.7V19.911L5.98666 20.0667C6.29209 20.1644 6.86393 20.3038 7.69389 20.4407C9.0573 20.6656 10.6622 20.8 12.5 20.8C14.3378 20.8 15.9427 20.6656 17.3061 20.4407C18.1361 20.3038 18.7079 20.1644 19.0133 20.0667L19.5 19.911V18.7ZM14.6 15.2C16.5332 15.2 18.1 16.7668 18.1 18.7V18.8597C17.8075 18.9275 17.4667 18.9953 17.0783 19.0593C15.7893 19.2719 14.2606 19.4 12.5 19.4C10.7394 19.4 9.21067 19.2719 7.92174 19.0593C7.53331 18.9953 7.19251 18.9275 6.9 18.8597V18.7C6.9 16.7668 8.4668 15.2 10.4 15.2H14.6Z"
        fill="#404040"
      />
      <mask
        id="mask0_1325_1828"
        maskUnits="userSpaceOnUse"
        x="5"
        y="4"
        width="15"
        height="17"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 4C10.1807 4 8.3 5.8807 8.3 8.2C8.3 10.6205 10.2427 13.1 12.5 13.1C14.7573 13.1 16.7 10.6205 16.7 8.2C16.7 5.8807 14.8193 4 12.5 4ZM9.7 8.2C9.7 9.91186 11.1011 11.7 12.5 11.7C13.8989 11.7 15.3 9.91186 15.3 8.2C15.3 6.6539 14.0461 5.4 12.5 5.4C10.9539 5.4 9.7 6.6539 9.7 8.2ZM19.5 18.7C19.5 15.9936 17.3064 13.8 14.6 13.8H10.4C7.6936 13.8 5.5 15.9936 5.5 18.7V19.911L5.98666 20.0667C6.29209 20.1644 6.86393 20.3038 7.69389 20.4407C9.0573 20.6656 10.6622 20.8 12.5 20.8C14.3378 20.8 15.9427 20.6656 17.3061 20.4407C18.1361 20.3038 18.7079 20.1644 19.0133 20.0667L19.5 19.911V18.7ZM14.6 15.2C16.5332 15.2 18.1 16.7668 18.1 18.7V18.8597C17.8075 18.9275 17.4667 18.9953 17.0783 19.0593C15.7893 19.2719 14.2606 19.4 12.5 19.4C10.7394 19.4 9.21067 19.2719 7.92174 19.0593C7.53331 18.9953 7.19251 18.9275 6.9 18.8597V18.7C6.9 16.7668 8.4668 15.2 10.4 15.2H14.6Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1325_1828)">
        <rect x="0.5" width="24" height="24" fill="#404040" />
        <mask
          id="mask1_1325_1828"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="25"
          height="24"
        >
          <rect x="0.5" width="24" height="24" fill="white" />
        </mask>
        <g mask="url(#mask1_1325_1828)"></g>
      </g>
    </svg>
  );

  return (
    <SvgIcon viewBox="0 0 16 16" className={className}>
      {svg}
    </SvgIcon>
  );
};

AccountIcon.defaultProps = {} as Partial<IIconProps>;

export default AccountIcon;

import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '@react/components/props/common/svgicon.props';

export interface IConnectionPlusPlusIconProps extends ISvgIconProps {}

const ConnectionPlusPlusIcon = (props: IConnectionPlusPlusIconProps) => {
  const { color, className } = props;
  const svg = (
    <svg width="11" height="11" viewBox="0 0 11 11">
      <defs>
        <path id="xcto0uw9ca" d="M4.2 0v2.8H7v1.4H4.2V7H2.8V4.2H0V2.8h2.8V0h1.4z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-397 -286) translate(0 263) translate(271) translate(126 23) translate(2 2)">
                  <mask id="iwlkh8ctcb" fill="#fff">
                    <use xlinkHref="#xcto0uw9ca" />
                  </mask>
                  <use fill="#D8D8D8" xlinkHref="#xcto0uw9ca" />
                  <g fill="#404040" mask="url(#iwlkh8ctcb)">
                    <path d="M0 0H8.4V8.4H0z" transform="translate(-.7 -.7)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );

  return (
    <SvgIcon
      viewBox="0 0 11 11"
      htmlColor={color}
      className={className}
    >
      {svg}
    </SvgIcon>
  );
};

ConnectionPlusPlusIcon.defaultProps = {
  color: '#000000',
} as Partial<IConnectionPlusPlusIconProps>;

export default ConnectionPlusPlusIcon;
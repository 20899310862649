import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '@react/components/props/common/svgicon.props';

export interface ISearchIconProps extends ISvgIconProps {}

const SearchIcon = (props: ISearchIconProps) => {
  const { color } = props;
  const svg = (
    <g fill="none" fillRule="evenodd" transform="translate(-3 -3)">
      <path d="M0 0h20v20H0z" />
      <path id="a" fill={color} d="M11.69 12.935a5.29 5.29 0 1 1 1.245-1.245l3.476 3.476c.342.343.34.89 0 1.23l-.015.015a.87.87 0 0 1-1.23 0l-3.476-3.476zm-3.066-.266a4.046 4.046 0 1 0 0-8.09 4.046 4.046 0 0 0 0 8.09z" />
    </g>
  );

  return (
    <SvgIcon viewBox="0 0 14 14" htmlColor={color} fontSize="inherit">
      {svg}
    </SvgIcon>
  );
};

SearchIcon.defaultProps = {
  color: '#404040',
} as Partial<ISearchIconProps>;

export default SearchIcon;
import React from 'react';
import { SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ISvgIconProps } from '../props/common/svgicon.props';

export interface ISNikkeiIconProps extends ISvgIconProps {
  reverseColor?: boolean;
  viewBox?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '98px',
      height: '35px',
    },
  })
);

const ScoutAsiaNikkeiIcon = (props: ISNikkeiIconProps) => {
  const { className, viewBox } = props;
  const classes = useStyles();
  const svg = (
    <svg
      width="173"
      height="36"
      viewBox="0 0 173 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_488_9591)">
        <path
          d="M0.0877708 28.3416C1.98773 29.8758 5.07908 30.8385 7.11072 30.8385C8.47769 30.8385 9.36183 30.5342 9.36183 29.6584C9.36183 28.8696 8.74105 28.5652 7.54966 28.3043L6.00084 27.913C2.33261 27.1242 -0.00628662 25.4161 -0.00628662 21.8758C-0.00628662 17.764 3.43621 15.4472 8.12027 15.4472C10.9483 15.4472 13.1116 16.0186 15.0554 17.1988V22.8447C13.1555 21.4907 11.036 20.5714 8.78494 20.5714C7.59355 20.5714 6.7972 20.9627 6.7972 21.7516C6.7972 22.4969 7.37408 22.7578 8.34601 23.0186L10.1582 23.5466C14.4848 24.6832 16.2092 26.4348 16.2092 29.7578C16.2092 33.9565 12.541 36.0124 7.63744 36.0124C4.85334 36.0124 1.93757 35.354 0.0815003 34.1304V28.354L0.0877708 28.3416Z"
          fill="black"
        />
        <path
          d="M28.399 15.4348C30.4307 15.4348 32.3306 15.8261 33.7415 16.8323V22.6087C32.4623 21.6894 31.0891 21.2112 29.4587 21.2112C26.8941 21.2112 24.8625 22.7453 24.8625 25.7205C24.8625 28.6957 26.8941 30.2298 29.4587 30.2298C31.0953 30.2298 32.4623 29.7516 33.7415 28.8323V34.6087C32.3306 35.6149 30.4307 36.0062 28.399 36.0062C22.4358 36.0062 18.0214 32.0683 18.0214 25.7267C18.0214 19.3851 22.4358 15.4472 28.399 15.4472V15.4348Z"
          fill="black"
        />
        <path
          d="M45.8436 36C39.8364 36 35.6854 31.8012 35.6854 25.7205C35.6854 19.6397 39.8364 15.441 45.8436 15.441C51.8507 15.441 56.0018 19.6397 56.0018 25.7205C56.0018 31.8012 51.8946 36 45.8436 36ZM45.8436 21.0807C43.7241 21.0807 42.5327 23.0062 42.5327 25.7205C42.5327 28.4348 43.7241 30.3168 45.8436 30.3168C47.963 30.3168 49.1983 28.4348 49.1983 25.7205C49.1983 23.0062 48.0069 21.0807 45.8436 21.0807Z"
          fill="black"
        />
        <path
          d="M77.8231 35.5652H70.8879V33.8571C69.8282 35.0807 67.8405 36 65.5893 36C61.2188 36 58.6103 33.1553 58.6103 28.7826V15.8758H65.5016V27.1615C65.5016 28.8261 66.2979 30.0062 68.0662 30.0062C69.3454 30.0062 70.543 29.1739 70.8942 27.8199V15.8758H77.8293V35.5652H77.8231Z"
          fill="black"
        />
        <path
          d="M90.7215 35.5652H83.8302V21.2174H80.3877V15.882H83.8302V10.4596H90.7215V15.882H94.6092V21.2174H90.7215V35.5652Z"
          fill="black"
        />
        <path
          d="M113.201 8.69565L123.535 35.559H116.336L114.393 30.354H104.542L102.642 35.559H95.6187L105.952 8.69565H113.195H113.201ZM106.711 24.5776H112.279L109.495 17.0062L106.711 24.5776Z"
          fill="#0A7A73"
        />
        <path
          d="M124.996 28.3416C126.896 29.8758 129.987 30.8385 132.019 30.8385C133.386 30.8385 134.27 30.5342 134.27 29.6584C134.27 28.8696 133.649 28.5652 132.458 28.3043L130.909 27.913C127.241 27.1242 124.902 25.4161 124.902 21.8758C124.902 17.764 128.344 15.4472 133.028 15.4472C135.856 15.4472 138.02 16.0186 139.964 17.1988V22.8447C138.064 21.4907 135.944 20.5714 133.693 20.5714C132.502 20.5714 131.705 20.9627 131.705 21.7516C131.705 22.4969 132.282 22.7578 133.254 23.0186L135.066 23.5466C139.393 24.6832 141.117 26.4348 141.117 29.7578C141.117 33.9565 137.449 36.0124 132.546 36.0124C129.762 36.0124 126.846 35.354 124.996 34.1304V28.354V28.3416Z"
          fill="#0A7A73"
        />
        <path
          d="M147.344 14.2112C145.225 14.2112 143.412 12.7702 143.412 10.5342C143.412 8.29813 145.225 6.81366 147.344 6.81366C149.463 6.81366 151.276 8.25465 151.276 10.5342C151.276 12.8137 149.463 14.2112 147.344 14.2112ZM150.787 35.5652H143.851V15.8758H150.787V35.5652Z"
          fill="#0A7A73"
        />
        <path
          d="M165.453 23.7081C165.453 22.1739 164.042 21.0807 161.214 21.0807C159.314 21.0807 157.37 21.559 155.784 22.4782V16.9627C157.421 16.0869 160.067 15.4286 162.895 15.4286C168.77 15.4286 172.125 18.1863 172.125 23.3913V35.5528H165.453V34.5466C164.788 35.205 162.889 35.9006 160.769 35.9006C156.838 35.9006 153.483 33.7143 153.483 29.559C153.483 25.7515 156.837 23.3043 161.127 23.3043C162.807 23.3043 164.663 23.8323 165.453 24.3975V23.6956V23.7081ZM165.453 28.913C165.014 28.0807 163.904 27.559 162.669 27.559C161.302 27.559 159.929 28.1304 159.929 29.441C159.929 30.7515 161.296 31.3664 162.669 31.3664C163.904 31.3664 165.008 30.8385 165.453 30.0559V28.9192V28.913Z"
          fill="#0A7A73"
        />
        <path
          d="M7.0982 9.68323L1.74947 3.50311V9.52174H0V0H1.03463L6.34574 6.1118V0.130435H8.0952V9.68323H7.0982Z"
          fill="black"
        />
        <path
          d="M9.97638 0.130432H11.9516V9.52174H9.97638V0.130432Z"
          fill="black"
        />
        <path
          d="M16.7924 5.31677L15.7954 6.5031V9.52795H13.8202V0.130432H15.7954V4.29192L19.3508 0.130432H21.3385V0.211177L18.2033 3.90062L21.5517 9.44099V9.52174H19.3884L16.7924 5.31056V5.31677Z"
          fill="black"
        />
        <path
          d="M25.6275 5.31677L24.6305 6.5031V9.52795H22.6553V0.130432H24.6305V4.29192L28.1858 0.130432H30.1736V0.211177L27.0383 3.90062L30.3868 9.44099V9.52174H28.2235L25.6275 5.31056V5.31677Z"
          fill="black"
        />
        <path
          d="M31.4904 0.130432H37.1527V1.62733H33.4405V3.95652H36.6385V5.42236H33.4405V8.03105H37.1903V9.52174H31.4904V0.130432Z"
          fill="black"
        />
        <path
          d="M38.783 0.130432H40.7582V9.52174H38.783V0.130432Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_488_9591">
          <rect width="172.125" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <SvgIcon viewBox={viewBox} className={`${classes.root} ${className}`}>
      {svg}
    </SvgIcon>
  );
};

ScoutAsiaNikkeiIcon.defaultProps = {
  reverseColor: false,
} as Partial<ISNikkeiIconProps>;

export default ScoutAsiaNikkeiIcon;

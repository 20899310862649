import React, { ReactNode } from 'react';
import { AppBar } from '@mui/material';
import HsToolbar from '../toolbar/toolbar.component';
import useStyles from './style';

type IHsAppBarProps = {
  children: ReactNode,
};

function HsAppBar(props: IHsAppBarProps) {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar
        className={classes.appbar}
        elevation={1}
        position="fixed"
      >
        {children}
      </AppBar>
    </div>
  );
}

HsAppBar.Toolbar = HsToolbar;

export default HsAppBar;

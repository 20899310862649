import { defineMessages } from 'react-intl';

export const scope = 'src.react.components.menu.base';

export default defineMessages({
  myContent: {
    id: `${scope}.myContent`,
    defaultMessage: 'My Content', 
  },
  savedItems: {
    id: `${scope}.savedItems`,
    defaultMessage: 'Saved Items',
  },
  radar: {
    id: `${scope}.radar`,
    defaultMessage: 'Radar',
  },
  emailAlerts: {
    id: `${scope}.emailAlerts`,
    defaultMessage: 'Email Alerts',  
  },
});
import makeStyles from '@mui/styles/makeStyles';
import { FONT, APPBAR, SEARCH_DRAWER_WIDTH, NAVBAR, MENU } from '@constants/style';

export const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    height: `${APPBAR.HEIGHT}px`,
    width: `${SEARCH_DRAWER_WIDTH}px`,
    backgroundColor: '#fff',
    border: '#fff',
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      width: `${SEARCH_DRAWER_WIDTH - 20}px`,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  searchLogoInMobile: {
    display: 'none',
  },
  starterLogo: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    height: `${APPBAR.HEIGHT}px`,
    width: `${SEARCH_DRAWER_WIDTH}px`,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
    },
    border: 'none',
    background: 'transparent'
  },
  mobileEmptySpacing: {
    flexGrow: 1,
  },
  avatar: {
    height: `${APPBAR.HEIGHT}px`,
    width: `${APPBAR.HEIGHT}px`,
    cursor: 'pointer',
  },
  buttonLoginRoot: {
    width: '100%',
    height: '100%',
    borderRadius: 0,
    padding: `0px ${theme.spacing(4)}`,
  },
  tab: {
    height: `${APPBAR.HEIGHT}px`,
  },
  tabBorderRight: {
    borderRight: '1px solid #e2e2e2',
  },
  tabBorderLeft: {
    borderLeft: '1px solid #e2e2e2',
  },
  leftMenuPopper: {
    '& > div': {
      borderRadius: 0,
      '& ul': {
        padding: 0,
      },
      '& button': {
        width: '183px',
        height: '40px',
        fontSize: '14px',
        '&:hover': {
          backgroundColor: theme.palette.hover.main,
        },
        '& > span > span': {
          background: '#f50057',
        },
      },
    },
  },
  tabButton: {
    fontFamily: 'MetricWeb',
    borderRadius: 0,
    height: `${APPBAR.HEIGHT}px`,
    padding: '0 24px',
    textTransform: 'none',
    backgroundColor: 'white',
    '& p': {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: FONT.WEIGHT.MEDIUM,
      lineHeight: '60px',
      whiteSpace: 'nowrap',
    },
    '& span': {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: FONT.WEIGHT.MEDIUM,
      color: '#404040',
    },
    '&:hover': {
      backgroundColor: theme.palette.hover.main,
    },
    fontSize: '16px', 
    color: '#404040',
    fontWeight: 500
  },
  tabIcon24: {
    '& svg': {
      width: '24px',
      height: '24px',
    },
  },
  menuItem: {
    fontSize: '16px', 
    color: '#404040',
    fontWeight: 500
  },
  menuItemCountry: {
    height: '36px !important',
  },
  menuItemCountryHovered: {
    '&:hover': {
      backgroundColor: theme.palette.hover.main,
      cursor: 'pointer',
      '& p': {
        color: theme.palette.primary.main,
      },
    },
  },
  menuItemCountrySelected: {
    backgroundColor: theme.palette.hover.main,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    '& p': {
      color: theme.palette.primary.main,
    },
    paddingLeft: '4px !important',
  },
  menuItemSectorPrimaryHovered: {
    backgroundColor: `${theme.palette.hover.main} !important`,
    cursor: 'pointer',
    '& p': {
      color: `${theme.palette.primary.light} !important`,
    },
    '&:after': {
      borderColor: `transparent transparent transparent ${theme.palette.hover.main} !important`,
    },
    paddingLeft: '4px !important',
  },
  menuItemSectorPrimarySelected: {
    backgroundColor: `${theme.palette.hover.main} !important`,
    borderLeft: `4px solid ${theme.palette.primary.dark}`,
    cursor: 'pointer',
    '& p': {
      color: theme.palette.primary.main,
    },
    '&:after': {
      borderColor: `transparent transparent transparent ${theme.palette.hover.main} !important`,
    },
    paddingLeft: '4px !important',
  },
  menuItemSectorSecondaryHovered: {
    backgroundColor: theme.palette.hover.main,
    cursor: 'pointer',
    '& p': {
      color: `${theme.palette.primary.light} !important`,
    },
  },
  menuItemSectorSecondarySelected: {
    backgroundColor: theme.palette.primary.main,
    '& p': {
      color: 'white !important',
    },
  },
  dropDownContainer: {
    padding: '8px !important',
    [theme.breakpoints.down('sm')]: {
      height: 'fit-content',
      overflowY: 'auto',
      width: '100vw',
    },
  },
  dropDownContainerCountries: {
    [`@media screen and (max-height:${APPBAR.HEIGHT + MENU.HEIGHT.SECTOR.TOOLBAR + NAVBAR.MOBILE_MENU_ITEM}px)`]: {
      height: 'calc(100vh - 110px)',
      overflowY: 'auto',
      maxHeight: '330px',
    },
  },
  dropDownContainerSectors: {
    [`@media screen and (max-height:${APPBAR.HEIGHT + MENU.HEIGHT.SECTOR.TOOLBAR + (NAVBAR.MOBILE_MENU_ITEM * 2) + 20}px)`]: {
      height: 'calc(100vh - 155px)',
      overflowY: 'auto',
    },
  },
  menuItemClassName: {
    height: '46px !important',
    '& p': {
      fontWeight: `${FONT.WEIGHT.MEDIUM} !important`,
      fontSize: '16px !important',
      color: 'black',
    },
    '& > div:before': {
      borderWidth: '22px 0px 22px 12px !important',
    },
    '& > div:after': {
      borderWidth: '23px 0px 23px 13px !important',
    },
  },
  dropdownMenuAnimationDummyMask: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'white',
    left: 0,
    top: 0,
    zIndex: -1,
    boxShadow: '0 1px 3px 0 transparent,0 1px 2px 0 rgba(0,0,0,.15)',
  },
  scoutAsiaIcon: {
    height: '30px !important',
    width: '170px !important',
  },
  buttonConnectionLeadingIcon: {
    width: '24px',
    height: '24px',
    marginRight: '3px',
    verticalAlign: 'middle',
  },
  iconConnectionPlus: {
    position: 'relative',
    right: 0,
    left: '2px',
    bottom: '2px',
    verticalAlign: 'bottom',
    fontSize: '11px',
  },
  connectionPlusIcon: {
    fontSize: '33px',
  },
  tabMobile: {
    display: 'flex',
  },
  tabMobileButton: {
    justifyContent: 'space-between !important',
    fontSize: '16px',
    lineHeight: '16px',
    textTransform: 'none',
    color: '#404040',
    padding: '10px 16px 10px 16px',
    margin: '0px',
    width: '100%',
    '& p': {
      fontWeight: `${FONT.WEIGHT.REGULAR} !important`,
    },
    '& span': {
      justifyContent: 'space-between',
    },
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
    '&:hover p': {
      color: '#0c6460',
    },
  },
  popper: {
    [theme.breakpoints.down(NAVBAR.MOBLIE_MENU_WIDTH)]: {
      left: '4px !important',
      zIndex: '1301 !important',
    },
  },
  toolbarRoot: {
    flexGrow: 1,
    backgroundColor: '#fff',
  },
  simpleLogo: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    height: `${APPBAR.HEIGHT}px`,
    width: `${SEARCH_DRAWER_WIDTH}px`,
    backgroundColor: '#fff',
    border: '#fff',
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      width: `${SEARCH_DRAWER_WIDTH - 20}px`,
    },
  },
}));
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Typography,
  useMediaQuery,
  Box,
  Toolbar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { ROUTE_PATHS, SITE_URLS } from '@constants/routes';
import { FeatureCodeConst } from 'skyeye-common-const';
import {
  FEATURE_CODE,
  CONNEX,
  OBJECT_NAME,
  UI_ELEMENT,
  UI_ELEMENT_GROUP,
  COOKIE,
} from '@constants/common';
import SubscriptionFeature from '@react/components/subscriptionFeature/subscriptionFeature.component';
import ConnectionPlusFeatureDialog from '@react/components/dialog/connectionPlusFeature/connectionPlusFeature.component';
import ConnectionPlusPlusIcon from '@react/components/icons/connectionPlusPlusIcon.component';
import { NAVBAR } from '@constants/style';
import { useCustomOidc } from '@react/components/oidc/customOidcProvider.component';
import { logout as logoutAction } from '@redux/actions/account.actions';
import {
  userNameSelector,
  loggedInSelector,
  getSubscriptionFeatureListWidthRemoveSeperator,
} from '@redux/selectors/account.selector';
import { oidcUserSelector } from '@redux/selectors/oidc.selector';
import {
  getLocalStorageConnexButtonClicks,
  saveLocalStorageConnexButtonClicks,
  getLocalStorageConnexModalDoNotShowAgain,
  saveLocalStorageConnexModalDoNotShowAgain,
  getHostDomain,
  hideMenu,
  getSubcriptionFeatureReport,
} from '@react/utils/common.util';
import { setTimeout } from 'timers';
import { useCookies } from 'react-cookie';
import { updateSearchKeyword as updateSearchKeywordDispatch } from '@redux/actions/quickSearch.actions';
import { useStyles } from './style';
import HsToolbarProfileAvatar from '../avatar/toolbarProfileAvatar.component';
import messages from './messages';
import { HsMenuListProfileMenuType } from '../menu/profile/menuListProfile.component';
import CustomLink from '../customLink.component';
import AtlasDataTrackable from '../atlas/atlas.component';
import NavbarInMobile from '../navbar/navbarMobile.component';
import SearchOutlineInput from '../searchOutlineInput/searchOutlineInput.component';
import MyContentButtonDropDownMenu from '../myContent/myContentButtonDropDownMenu.component';
import ScoutAsiaNikkeiIcon from '../icons/scoutAsiaNikkeiIcon.component';


interface IHsToolbarStateProps {
  isLogin?: boolean;
  userName?: string;
  user?: any;
  subcriptionFeaturePermissionModel?: any;
}

interface IHsToolbarDispatchProps {
  logout;
}

interface IHsToolbarOwnProps {
  /**
   * If `true`, toolbar display ScoutAsia icon only.
   *
   * It overides [isLogin].
   */
  isEmpty?: boolean;

  onLoginClick?: () => void;
}

type IHsToolbarProps = IHsToolbarStateProps &
IHsToolbarDispatchProps &
IHsToolbarOwnProps;

function HsToolbar(props: IHsToolbarProps) {
  const {
    isEmpty,
    isLogin,
    onLoginClick,
    userName,
    logout,
    user,
    subcriptionFeaturePermissionModel,
  } = props;
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const theme = useTheme();
  const oidc = useCustomOidc();
  
  const allReport = getSubcriptionFeatureReport(subcriptionFeaturePermissionModel);

  // Determine current width is
  // less than size lg (1000px)
  // Refer to https://material-ui.com/customization/breakpoints/
  const isNavbarMobile = useMediaQuery(
    theme.breakpoints.down(NAVBAR.MOBLIE_MENU_WIDTH),
    { noSsr: true }
  );
  const isOnlyIcon = useMediaQuery(
    theme.breakpoints.between(NAVBAR.MOBLIE_MENU_WIDTH, NAVBAR.ICON_ONLY_WIDTH),
    { noSsr: true }
  );
  const isFullWitdh = useMediaQuery(
    theme.breakpoints.down(NAVBAR.MOBLIE_DROPDOWN_WIDTH),
    { noSsr: true }
  );
  const [showDialog, setShowDialog] = useState(false);
  const [isSearchShowInMobile, setIsSearchShowInMobile] = useState(false);
  const [navbarAnchor, setNavbarAnchor] = useState(null);

  const [openLeftMenu, setOpenLeftMenu] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const [, setCookie] = useCookies([COOKIE.CONNECTION_PLUS.SCOUT_TOKEN]);

  const rightDrawerNavbarOpen = Boolean(navbarAnchor);
  const handleNavbarDrawerClose = () => {
    setNavbarAnchor(null);
  };

  const goSAUrl =
    'https://www.scout.asia/nikkei/sgxtitanotc-referral.html?utm_campaign=Project%20225%20&utm_source=TitanOTC&utm_medium=NavMenuUpgrade';


  const prevOpen = useRef(openLeftMenu);
  useEffect(() => {
    if (prevOpen.current === true && openLeftMenu === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = openLeftMenu;
  }, [openLeftMenu]);

  const goToScoutAI = (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();
    history.push(`${SITE_URLS.EMAIL_ALERTS}`);
    isNavbarMobile && handleNavbarDrawerClose();
  };

  const goToMain = () => {
    setTimeout(() => {
      history.push(`${SITE_URLS.MAIN}`);
    }, 300);
  };

  const handleLoginClicked = () => {
    if (onLoginClick) {
      onLoginClick();
    }
  };

  const goToGoScoutAsia = (url) => {
    const newWindow = window.open();
    newWindow.opener = null;
    newWindow.location.href = url;
  };

  const goToOtherPage = (url: string) => {
    history.push(url);
    isNavbarMobile && handleNavbarDrawerClose();
  };

  const onProfileAvatarMenuItemSelect = (type: HsMenuListProfileMenuType) => {
    switch (type) {
      case HsMenuListProfileMenuType.logout: {
        const domain = getHostDomain(window.location.hostname, true);
        setCookie(COOKIE.CONNECTION_PLUS.SCOUT_TOKEN, '', {
          domain,
          expires: new Date(),
          path: COOKIE.CONNECTION_PLUS.PATH,
        });
        setCookie(COOKIE.P225.P225_TOKEN, '', {
          domain: window.location.hostname,
          expires: new Date(),
          path: COOKIE.P225.PATH,
        });
        logout(history, oidc.userManager, user.id_token);
        break;
      }
      case HsMenuListProfileMenuType.myAccount: {
        history.push(`${SITE_URLS.PROFILE}`);
        break;
      }
      case HsMenuListProfileMenuType.alertSettings: {
        history.push(`${SITE_URLS.ALERT_SETTINGS_SCOUT_AI}`);
        break;
      }
      case HsMenuListProfileMenuType.faqs: {
        history.push(`${SITE_URLS.CMS_FAQ}`);
        break;
      }
      case HsMenuListProfileMenuType.upgrade: {
        goToGoScoutAsia(goSAUrl);
        break;
      }
      default: {
        break;
      }
    }
  };

  const onButtonConnectionPlusClick = () => {
    const doNotShowAgain = getLocalStorageConnexModalDoNotShowAgain();
    const numClick = getLocalStorageConnexButtonClicks() + 1;

    saveLocalStorageConnexButtonClicks(numClick);

    if (numClick <= CONNEX.BUTTON_MAX_NUMBER_CLICKS && !doNotShowAgain) {
      setShowDialog(true);
      return;
    }
    goToConnectionPlus();
  };

  const handleOnDoNotShowThisAgainChange = (checked: boolean) => {
    saveLocalStorageConnexModalDoNotShowAgain(checked);
  };

  const onButtonTryFeatureNowClick = () => {
    goToConnectionPlus();
  };

  const goToConnectionPlus = () => {
    const a = document.createElement('a');
    a.setAttribute('target', '_blank');
    a.setAttribute('href', process.env.CONNECTS_ENDPOINT);

    document.body.appendChild(a);
    a.click();
  };

  const renderMyContent = () => {
    return <MyContentButtonDropDownMenu />;
  };

  const goToSearchArticleWithReport = () => {
    const queryObj = {
      newsQuery: {
        data: {
          searchStr: '',
          publishers:allReport,
        },
      },
      companyQuery: {
        data: {
          searchStr: '',
        },
      },
    };

    history.push(ROUTE_PATHS.SEARCH_ARTICLE, { ...queryObj });
  };

  const goToOverview = () => {
    history.push(ROUTE_PATHS.CMS_OVERVIEW);
  };

  const goToSearch = (url: string) => {
    const queryObj = {
      newsQuery: {
        data: {
          searchStr: '',
        },
      },
      companyQuery: {
        data: {
          searchStr: '',
        },
      },
    };

    history.push(url, { ...queryObj });
  };

  const renderMenuItem = (content) => {
    if (isNavbarMobile) {
      return <></>;
    }
    return (
      <>
        {content}
      </>
    );
  };

  const renderNews = () => {
    return (
      <div className={`${classes.tab} ${classes.tabBorderRight}`}>
        <Button
          key={intl.formatMessage(messages.news)}
          onClick={() => {
            goToSearch(ROUTE_PATHS.SEARCH_ARTICLE);
          }}
          className={classes.tabButton}
          {...AtlasDataTrackable({
            targetAttribute: 'news-button',
            atlastracking: {
              uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
              uiElement: UI_ELEMENT.NAVBAR_NEWS,
              objectName: OBJECT_NAME.NEWSSEARCH_RESULT,
            },
          })}
        >
          {intl.formatMessage(messages.news)}
        </Button>
      </div>
    );
  };

  const renderCompany = () => {
    return (
      <div className={`${classes.tab} ${classes.tabBorderRight}`}>
        <Button
          key={intl.formatMessage(messages.companies)}
          onClick={() => {
            goToSearch(ROUTE_PATHS.SEARCH_COMPANY);
          }}
          className={classes.tabButton}
          {...AtlasDataTrackable({
            targetAttribute: 'news-button',
            atlastracking: {
              uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
              uiElement: UI_ELEMENT.NAVBAR_COMPANIES,
              objectName: OBJECT_NAME.COMPANYSEARCH_RESULT,
            },
          })}
        >
          {intl.formatMessage(messages.companies)}
        </Button>
      </div>
    );
  };

  const renderReport = () => {
    return (
      <div className={`${classes.tab} ${classes.tabBorderRight}`}>
        <Button
          key={intl.formatMessage(messages.reports)}
          onClick={() => {
            goToSearchArticleWithReport();
          }}
          className={classes.tabButton}
          {...AtlasDataTrackable({
            targetAttribute: 'news-button',
            atlastracking: {
              uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
              uiElement: UI_ELEMENT.NAVBAR_REPORTS,
              objectName: OBJECT_NAME.REPORTSEARCH_RESULT,
            },
          })}
        >
          {intl.formatMessage(messages.reports)}
        </Button>
      </div>
    );
  };

  const renderOverview = () => {
    return (
      <div className={`${classes.tab} ${classes.tabBorderRight}`}>
        <Button
          key={intl.formatMessage(messages.macroOverview)}
          onClick={() => {
            goToOverview();
          }}
          className={classes.tabButton}
          {...AtlasDataTrackable({
            targetAttribute: 'news-button',
            atlastracking: {
              uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
              uiElement: UI_ELEMENT.NAVBAR_MACRO,
              objectName: OBJECT_NAME.GO_MACRO,
            },
          })}
        >
          {intl.formatMessage(messages.macroOverview)}
        </Button>
      </div>
    );
  };

  const renderLoggedInTabs = (
    <Fragment>
      {renderMenuItem(renderNews())}
      {renderMenuItem(renderCompany())}
      {renderMenuItem(renderReport())}
      <SubscriptionFeature
        isMobile={isNavbarMobile}
        subscription={FeatureCodeConst.CONNECTION_PLUS_CREDIT}
        access={
          <div className={`${classes.tab} ${classes.tabBorderRight}`}>
            <Button
              key={intl.formatMessage(messages.bannerTitle)}
              className={classes.tabButton}
              onClick={() => {
                onButtonConnectionPlusClick && onButtonConnectionPlusClick();
              }}
              {...AtlasDataTrackable({
                targetAttribute: 'connection-plus',
                atlastracking: {
                  uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
                  uiElement: UI_ELEMENT.NAVBAR,
                  objectName: OBJECT_NAME.CONNECTIONS,
                  objectNameDetails: OBJECT_NAME.CONNECTIONS,
                },
              })}
            >
              {intl.formatMessage(messages.bannerTitle)}
              <ConnectionPlusPlusIcon
                className={classes.iconConnectionPlus}
              />
            </Button>
            <ConnectionPlusFeatureDialog
              open={showDialog}
              onClose={() => {
                setShowDialog(false);
              }}
              onDoNotShowThisAgainChange={handleOnDoNotShowThisAgainChange}
              onTryFeatureNowClick={onButtonTryFeatureNowClick}
            />
          </div>
        }
        deny={<></>}
      />
      {renderMenuItem(renderOverview())}
      <SubscriptionFeature
        isMobile={isNavbarMobile}
        subscription={FEATURE_CODE.SEARCH_TEXTBOX}
        access={<></>}
        deny={<>{isNavbarMobile || <SearchOutlineInput />}</>}
      />
      {(!isSearchShowInMobile || !isNavbarMobile) && (
        <div
          style={{
            display: 'flex',
            marginRight: (isNavbarMobile) ? 0 : '32px',
            flex: (isNavbarMobile) ? '1 1 auto' : 'initial'
          }}
          id="blankRow"
        />
      )}
      {!isNavbarMobile && renderMyContent()}
      <SubscriptionFeature
        isMobile={isNavbarMobile}
        subscription={FEATURE_CODE.HIDE_ARTICLE_TOOLBAR_RIGHTTABS}
        access={<></>}
        deny={
          <>
            <HsToolbarProfileAvatar />
          </>
        }
      />
      <>
        {isNavbarMobile ? (
          <NavbarInMobile
            dropdownButtonClick={(event) => {
              setNavbarAnchor(event.currentTarget);
            }}
            rightDrawerNavbarOpen={rightDrawerNavbarOpen}
            navbarAnchor={navbarAnchor}
            handleNavbarDrawerClose={handleNavbarDrawerClose}
            onButtonConnectionPlusClick={onButtonConnectionPlusClick}
            showDialog={showDialog}
            hideDialog={() => {
              setShowDialog(false);
            }}
            handleOnDoNotShowThisAgainChange={handleOnDoNotShowThisAgainChange}
            onButtonTryFeatureNowClick={onButtonTryFeatureNowClick}
            goToScoutAI={goToScoutAI}
            goToOtherPage={(url) => goToOtherPage(url)}
            onProfileAvatarMenuItemSelect={onProfileAvatarMenuItemSelect}
            isSearchShowInMobile={isSearchShowInMobile}
            setIsSearchShowInMobile={setIsSearchShowInMobile}
            {...props}
          />
        ) : (
          <div />
        )}
      </>
    </Fragment>
  );

  const renderNotLoginTabs = (
    <Fragment>
      <div style={{ display: 'flex', flex: '1 1 auto' }} />
      <div className={classes.avatar}>
        <CustomLink to={`${SITE_URLS.MAIN}`}>
          <Button
            className={classes.buttonLoginRoot}
            onClick={() => {
              handleLoginClicked();
            }}
            disableRipple
          >
            <Typography variant="body1">
              {intl.formatMessage(messages.login)}
            </Typography>
          </Button>
        </CustomLink>
      </div>
    </Fragment>
  );

  const dataTrackable = {
    targetAttribute: 'home-logo',
    atlastracking: {
      uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
      uiElement: UI_ELEMENT.NAVBAR_LOGO,
      objectName: OBJECT_NAME.GO_HOMEPAGE
    },
  };

  const renderRest = () => {
    if (isEmpty) {
      return <Fragment />;
    }

    return isLogin ? renderLoggedInTabs : renderNotLoginTabs;
  };

  const renderScoutAsiaLogo = (isStarter: boolean) => (
    <Box
      role="button"
      component="button"
      className={classnames({
        [classes.starterLogo]: isStarter,
        [classes.logo]: !isStarter,
        [classes.tabBorderRight]: !isNavbarMobile,
        [classes.searchLogoInMobile]: isSearchShowInMobile && isNavbarMobile,
      })}
      onClick={
        isStarter
          ? null
          : () => {
            goToMain();
          }
      }
      onKeyDown={
        isStarter
          ? null
          : () => {
            goToMain();
          }
      }
      {...AtlasDataTrackable(dataTrackable)}
    >
      <ScoutAsiaNikkeiIcon className={classes.scoutAsiaIcon} />
    </Box>
  );

  return (
    <Toolbar disableGutters>
      <SubscriptionFeature
        subscription={FEATURE_CODE.HIDE_ARTICLE_TOOLBAR_RIGHTTABS}
        access={renderScoutAsiaLogo(true)}
        deny={renderScoutAsiaLogo(false)}
      />
      <Box component="div" className={classes.dropdownMenuAnimationDummyMask} />
      {!hideMenu() && renderRest()}
    </Toolbar>
  );
}

const mapStateToProps = (state: any) => ({
  isLogin: loggedInSelector(state),
  userName: userNameSelector(state),
  user: oidcUserSelector(state),
  subcriptionFeaturePermissionModel: getSubscriptionFeatureListWidthRemoveSeperator(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(
    {
      logout: logoutAction,
      updateSearchKeyword: updateSearchKeywordDispatch,
    },
    dispatch
  ),
});

export default connect<
IHsToolbarStateProps,
IHsToolbarDispatchProps,
IHsToolbarOwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(HsToolbar);

import makeStyles from '@mui/styles/makeStyles';
import { FONT, ZINDEX } from '@constants/style';

const drawerWidth = '300px';

export const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    overflowY: 'visible',
    zIndex: ZINDEX.RIGHT_DRAWER,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 0 0 20px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.up('sm')]: {
      minHeight: '60px',
    },
    '& > p': {
      color: theme.palette.secondary.contrastText,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: FONT.WEIGHT.MEDIUM,
    },
  },
  drawerCloseIcon: {
    fontSize: '24px',
    color: theme.palette.secondary.contrastText,
  },
  drawerContentRoot: {
    overflow: 'hidden',
    display: 'flex',
    position: 'relative',
    height: '100%',
  },
  textAlertSettingsRoot: {
    textAlign: 'right',
    padding: '8px 16px 0px 16px',
    lineHeight: theme.spacing(2),
  },
  savedInputContainer: {
    padding: '16px 16px 8px',
    borderBottom: '2px solid grey',
  },
  targetInputContainer: {
    padding: '8px',
  },
  addListButtonContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  showInput: {
    backgroundColor: '#eef4f6',
  },
  addListButton: {
    width: '100%',
    border: '1px solid #1b1b1b',
    color: '#404040',
    height: '40px',
  },
  listRoot: {
    flex: '0 0 100%',
  },
  addToListButton: {
    width: '100%',
    zIndex: 1,
  },
  emptyListIcon: {
    height:48,
    width:48,
  },
  emptyListMessage:{
    fontSize: 20,
    lineHeight: '24px',
    paddingTop: 7,
    width: 208,
    textAlign: 'center',
  },
  list: {
    paddingTop: 0,
    width: '100%',
  },
  listItem: {
    padding: '8px 8px 0',
  },
  addIcon: {
    marginRight: '0',
  },
  targetListCreate: {
    paddingTop: '8px',
  },
  saveSearchNameInput: {
    width: 'calc(100% - 16px)',
    marginRight: '16px',
    '& input': {
      height: '40px',
      padding: '0',
    },
    '& input::placeholder': {
      color: '#8a8a8a',
      opacity: 1,
      fontWeight: FONT.WEIGHT.MEDIUM,
    },
    '& input:-ms-input-placeholder': {
      color: '#8a8a8a',
      opacity: 1,
      fontWeight: FONT.WEIGHT.MEDIUM,
    },
    '& input::-ms-input-placeholder': {
      color: '#8a8a8a',
      opacity: 1,
      fontWeight: FONT.WEIGHT.MEDIUM,
    }, 
  },
  targetListNameInput: {
    width: '100%',
    '& >div': {
      borderRadius: 0,
    },
    '& input': {
      backgroundColor: 'white',
      fontSize: '16px',
      padding: '10px 16px',
    },
    '& input::placeholder': {
      color: '#8a8a8a',
      opacity: 1,
    },
    '& input:-ms-input-placeholder': {
      color: '#8a8a8a',
      opacity: 1,
    },
    '& input::-ms-input-placeholder': {
      color: '#8a8a8a',
      opacity: 1,
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
  },
  saveButton: {
    width: '100%',
    height: '40px',
  },
  createButton: {
    color: '#404040',
    family: FONT.FAMILY,
    fontSize: '14px',
    fontWeight: FONT.WEIGHT.BOLD,
    letterSpacing: '1.3px',
    cursor: 'pointer',
    paddingTop: '10px',
    '&:hover': {
      color: '#007b78',
    },
  },
  addSelectedToList: {
    padding: '10px 60px',
    fontWeight: FONT.WEIGHT.SEMIBOLD,
    fontSize: '16px',
    color: '#404040',
    textAlign: 'center',
    lineHeight: '20px',
    borderBottom: '1px solid grey',
  },
  drawerScroll: {
    overflowY: 'scroll',
  },
  selection: {
    display: 'flex',
    background: '#ffeef0',
    padding: 10,
  },
  iconWarningRoot: {
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.error.dark,
    '& > svg': {
      fontSize: '22px',
      '& > g > path:last-child': {
        fill: '#ffeef0',
      },
    },
  },
  parallelBody: {
    marginTop: '61px',
    width: '200px',
  },
  navbarRoot: {
    width: '180px',
    padding:'8px 16px',

  },
  navbarList: {
    padding: 0,
    '& div:hover': {
      backgroundColor: '#f9f9f9 !important',
      '& p': {
        color: '#0c6460 !important',
      },
    },
    '& > .MuiListItem-divider': {
      borderBottom: '1px solid #979797',
    },
  },
  navbarListItem: {
    padding: '8px 0',
  
  },
  divider: {
    borderTop: 'solid 1px #DCDDDF',
    marginBottom:'8px',
  },
  buttonConnectionLeadingIcon: {
    width: '24px',
    height: '24px',
    verticalAlign: 'middle',
  },
  iconConnectionPlus: {
    position: 'absolute',
    verticalAlign: 'bottom',
    fontSize: '10px',
  },
  menuMargin: {
    marginLeft: '12px',
  },
  menuNoMargin: {
    marginLeft: 0,
  },
  drawerNavbarRoot: {
    '& .MuiPopover-paper': {
      borderRadius: 0,
      right: '0 !important',
      top: '60px !important',
      left: 'unset !important',
    },
  },
}));
import { defineMessages } from 'react-intl';

export const scope = 'src.react.components.header.search.text.box';

export default defineMessages({
  searchDropdownStylePlaceholder: {
    id: `${scope}.searchDropdownStylePlaceholder`,
    defaultMessage: 'Search keyword',
  },
  clearSearch: {
    id: `${scope}.clearSearch`,
    defaultMessage: 'Clear past keywords',
  },
  recentSearches: {
    id: `${scope}.recentSearches`,
    defaultMessage: 'RECENT KEYWORDS',
  },
});
import { IAtlasTracking } from '@models/atlasTracking';

/**
 * @param uiElementGroup
 * @param uiElement 
 * @param objectName 
 * @param objectNameDetails 
 * @param uiElementDetails 
 * @returns
 * 
 * The table data trace is written this way for now. 
 * scout AI base menu,too
 */
export const constructAtlasTrackingObj = (uiElementGroup?, uiElement?, objectName?, objectNameDetails?, uiElementDetails?) => {
  const atlasTracking: IAtlasTracking = {
    uiElementGroup,
    uiElement,
    objectName,
    objectNameDetails,
    uiElementDetails
  };
  return atlasTracking;
};
import React, { forwardRef, useContext } from 'react';
import { MenuList, MenuItem, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import AtlasDataTrackable from '@react/components/atlas/atlas.component';
import SubscriptionFeature from '@react/components/subscriptionFeature/subscriptionFeature.component';
import { COOKIE, FEATURE_CODE, OBJECT_NAME, UI_ELEMENT, UI_ELEMENT_GROUP } from '@constants/common';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getIsPublicUser, getUserRole } from '@redux/selectors/account.selector';
import { setUserRole, logout as logoutAction } from '@actions/account.actions';
import { getFeatureRoles } from '@react/utils/featureToggle.util';
import { FeatureToggleContext } from '@react/components/featureToggle/featureToggleContext';
import { SITE_URLS } from '@constants/routes';
import { useHistory } from 'react-router';
import { getHostDomain } from '@react/utils/common.util';
import { bindActionCreators } from 'redux';
import { useCookies } from 'react-cookie';
import { useCustomOidc } from '@react/components/oidc/customOidcProvider.component';
import { oidcUserSelector } from '@redux/selectors/oidc.selector';
import messages from './messages';
import { useStyles } from './style';

export enum HsMenuListProfileMenuType {
  alertSettings,
  myAccount,
  faqs,
  logout,
  upgrade,
  roleChange,
}


const HsMenuListProfile = forwardRef<HTMLUListElement, IProps>((props: IProps, ref) => {
  const { autoFocusItem, className, id, user, logout, onKeyDown, onItemClick, onCloseDropdown } = props;
  const classes = useStyles();
  const isPublicUser: boolean = useSelector(getIsPublicUser);
  const activeUserRole = useSelector(getUserRole);
  const { featureJSON } = useContext(FeatureToggleContext);
  const featureRoles = getFeatureRoles(featureJSON, activeUserRole);
  const reduxDispatch = useDispatch();
  const history = useHistory();
  const [, setCookie] = useCookies([COOKIE.CONNECTION_PLUS.SCOUT_TOKEN]);
  const oidc = useCustomOidc();
  
  const renderItem = (item) => (
    <MenuItem
      key={`menu-profile-item-${item.label.defaultMessage}`}
      component="button"
      onClick={item.onClick ? item.onClick : (_) => {
        onItemClick && onItemClick(item.type);
      }}
      {...onItemClick ?
        AtlasDataTrackable({ ...item.dataTrackable }) :
        {}
      }
    >
      <Typography variant="body2">
        <FormattedMessage {...item.label} values={item.labelProps} />
      </Typography>
    </MenuItem>
  );

  const items = [
    {
      label: messages.myAccount,
      type: HsMenuListProfileMenuType.myAccount,
      onClick: () => { history.push(`${SITE_URLS.PROFILE}`); },
      dataTrackable: {
        targetAttribute: 'handleClick-myAccount',
        atlastracking: {
          uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
          uiElement: UI_ELEMENT.NAVBAR_ACCOUNT,
          objectName: OBJECT_NAME.MY_ACCOUNT,
        },
      },
    },
    ...(isPublicUser ? [] : featureRoles.map(role => ({
      label: messages.changeRole,
      labelProps: { role },
      onClick: () => { reduxDispatch(setUserRole(role)); },
      type: HsMenuListProfileMenuType.roleChange,
    }))),
    {
      label: messages.faqs,
      type: HsMenuListProfileMenuType.faqs,
      onClick: () => { history.push(`${SITE_URLS.CMS_FAQ}`); onCloseDropdown(); },
      dataTrackable: {
        targetAttribute:  'handleClick-FAQ',
        atlastracking: {
          uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
          uiElement: UI_ELEMENT.NAVBAR_ACCOUNT,
          objectName: OBJECT_NAME.FAQ,
        },
      },
    },
    {
      label: messages.logout,
      type: HsMenuListProfileMenuType.logout,
      onClick: () => {
        const domain = getHostDomain(window.location.hostname, true);
        setCookie(COOKIE.CONNECTION_PLUS.SCOUT_TOKEN, '', {
          domain,
          expires: new Date(),
          path: COOKIE.CONNECTION_PLUS.PATH,
        });
        setCookie(COOKIE.P225.P225_TOKEN, '', {
          domain: window.location.hostname,
          expires: new Date(),
          path: COOKIE.P225.PATH,
        });
        logout(history, oidc.userManager, user.id_token);
      },
      dataTrackable: {
        targetAttribute: 'handleClick-logout',
        atlastracking: {
          uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
          uiElement: UI_ELEMENT.NAVBAR_ACCOUNT,
          objectName: OBJECT_NAME.LOGOUT,
        },
      },
    },
  ];

  const goSAUrl =
    'https://www.scout.asia/nikkei/sgxtitanotc-referral.html?utm_campaign=Project%20225%20&utm_source=TitanOTC&utm_medium=NavMenuUpgrade';
  
  const goToGoScoutAsia = (url) => {
      const newWindow = window.open();
      newWindow.opener = null;
      newWindow.location.href = url;
  };
  const itemsFor225 = [
    {
      label: messages.upgrade,
      type: HsMenuListProfileMenuType.upgrade,
      onClick: () => {
        goToGoScoutAsia(goSAUrl);
        onCloseDropdown();
      }
    },
    {
      label: messages.faqs,
      type: HsMenuListProfileMenuType.faqs,
      onClick: () => { history.push(`${SITE_URLS.CMS_FAQ}`); onCloseDropdown(); },
      dataTrackable: {
        targetAttribute:  'handleClick-FAQ',
        atlastracking: {
          uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
          uiElement: UI_ELEMENT.NAVBAR_ACCOUNT,
          objectName: OBJECT_NAME.FAQ,
        },
      },
    },
    {
      label: messages.logout,
      type: HsMenuListProfileMenuType.logout,
      onClick: () => {
        const domain = getHostDomain(window.location.hostname, true);
        setCookie(COOKIE.CONNECTION_PLUS.SCOUT_TOKEN, '', {
          domain,
          expires: new Date(),
          path: COOKIE.CONNECTION_PLUS.PATH,
        });
        setCookie(COOKIE.P225.P225_TOKEN, '', {
          domain: window.location.hostname,
          expires: new Date(),
          path: COOKIE.P225.PATH,
        });
        logout(history, oidc.userManager, user.id_token);
      },
      dataTrackable: {
        targetAttribute: 'handleClick-logout',
        atlastracking: {
          uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
          uiElement: UI_ELEMENT.NAVBAR_ACCOUNT,
          objectName: OBJECT_NAME.LOGOUT,
        },
      },
    },
  ];

  return (
    <MenuList
      ref={ref}
      autoFocusItem={autoFocusItem}
      id={id}
      onKeyDown={onKeyDown}
      className={`${classes.root} ${className}`}
    >
      <SubscriptionFeature
        subscription={FEATURE_CODE.HIDE_ARTICLE_TOOLBAR_AVATAR}
        access={
          itemsFor225.map((item) => renderItem(item))
        }
        deny={
          items.map((item) => renderItem(item))
        }
      />
    </MenuList>
  );
});

const mapStateToProps = (state: any) => ({
  user: oidcUserSelector(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(
    {
      logout: logoutAction
    },
    dispatch
  ),
});

interface IStateProps {
  user?: any;
}

interface IDispatchProps {
  logout;
}

interface IOwnProps {
  isEmpty?: boolean;

  onLoginClick?: () => void;
  autoFocusItem?: boolean,
  className?: string,
  id: string,
  onKeyDown?: (event: React.KeyboardEvent<HTMLUListElement>) => void,
  onItemClick?: (type: HsMenuListProfileMenuType) => void,
  onCloseDropdown?: ()=>void
}

type IProps = IStateProps &
IDispatchProps &
IOwnProps;

export default connect<
IStateProps,
IDispatchProps,
IOwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(HsMenuListProfile);

import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import classnames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router';
import AtlasDataTrackable from '../atlas/atlas.component';
import HsRightDrawerNavbar from '../drawer/rightDrawer/rightDrawerNavbar.component';
import DropdownMenuIcon from '../icons/dropdownMenu.component';
import { HsMenuListProfileMenuType } from '../menu/profile/menuListProfile.component';
import messages from '../toolbar/messages';
import SearchTextBox from '../searchTextBox/searchTextBox.component';
import { useStyles } from './style';
import SearchOutlineInput from '../searchOutlineInput/searchOutlineInput.component';

interface INavbarMobile {
  dropdownButtonClick:(event) => void,
  rightDrawerNavbarOpen: boolean,
  handleNavbarDrawerClose: () => void,
  navbarAnchor: any,
  onButtonConnectionPlusClick: () => void,
  showDialog: boolean,
  hideDialog: () => void,
  handleOnDoNotShowThisAgainChange: (checked:boolean) => void,
  onButtonTryFeatureNowClick: () => void,
  goToScoutAI: (event: React.SyntheticEvent<HTMLElement>) => void,
  goToOtherPage: (url:string) => void,
  onProfileAvatarMenuItemSelect: (type: HsMenuListProfileMenuType) => void,
  isSearchShowInMobile:boolean,
  setIsSearchShowInMobile:(value:boolean) => void,
}

export default function NavbarMobile(props:INavbarMobile) {
  const { 
    dropdownButtonClick, rightDrawerNavbarOpen, navbarAnchor, handleNavbarDrawerClose, onButtonConnectionPlusClick,
    showDialog, hideDialog, handleOnDoNotShowThisAgainChange, onButtonTryFeatureNowClick, goToScoutAI, goToOtherPage,
    onProfileAvatarMenuItemSelect, isSearchShowInMobile, setIsSearchShowInMobile,
  } = props;
  const classes = useStyles();

  return (
    <div className={classnames({
      [classes.tabInMobile]: isSearchShowInMobile,
    })}
    >
      {isSearchShowInMobile ?  
        <SearchOutlineInput /> :
        <div className={classnames(classes.tab, classes.alignRight)}>
          <IconButton
            type="submit"
            className={classnames(classes.searchIconButton)}
            aria-label="search"
            onClick={()=>{ 
              setIsSearchShowInMobile(true);
            }}
            size="large"
          >
            <SearchIcon color="secondary" fontSize="medium" />
          </IconButton>
          <IconButton
            className={classes.dropdownMenuButton}
            onClick={dropdownButtonClick}
            size="large"
          >
            <DropdownMenuIcon className={classes.dropdownMenuIcon} />
          </IconButton>
          <HsRightDrawerNavbar
            open={rightDrawerNavbarOpen}
            navbarAnchor={navbarAnchor}
            onClose={handleNavbarDrawerClose}
            onButtonConnectionPlusClick={onButtonConnectionPlusClick}
            AtlasDataTrackable={AtlasDataTrackable}
            showDialog={showDialog}
            hideDialog={hideDialog}
            handleOnDoNotShowThisAgainChange={handleOnDoNotShowThisAgainChange}
            onButtonTryFeatureNowClick={onButtonTryFeatureNowClick}
            goToScoutAI={goToScoutAI}
            goToOtherPage={goToOtherPage}
            messages={messages}
            onProfileAvatarMenuItemSelect={onProfileAvatarMenuItemSelect}
            {...props}
          />
        </div>}
      {isSearchShowInMobile && 
        <IconButton
          type="button"
          className={classes.searchIconButton}
          onClick={()=>{setIsSearchShowInMobile(false);}}
          size="large"
        >
          <CloseIcon color="secondary" fontSize="small" />
        </IconButton>}
    </div>
  );
}

import React, { useState, useRef } from 'react';
import {
  Button,
  useMediaQuery,
  Box,
  Badge,
  Popper,
  Slide,
  ClickAwayListener,
  Paper,
  List,
  ListItem,
} from '@mui/material';
import { NAVBAR } from '@constants/style';
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import SubscriptionFeature from '@react/components/subscriptionFeature/subscriptionFeature.component';
import {
  FEATURE_CODE,
  OBJECT_NAME,
  UI_ELEMENT,
  UI_ELEMENT_GROUP,
} from '@constants/common';
import { SITE_URLS } from '@constants/routes';
import { useHistory } from 'react-router';
import messages from './messages';
import { useStyles } from './styles';
import AtlasDataTrackable from '../atlas/atlas.component';
import BookOutlineIcon from '../icons/bookOutline.component';

interface IMyContentButtonDropDownMenu {}

const MyContentButtonDropDownMenu = (props: IMyContentButtonDropDownMenu) => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory<any>();
  const [dataTracking, setDataTracking] = useState<any>();
  const btnRef = useRef<HTMLButtonElement>();

  const isOnlyIcon = useMediaQuery(
    theme.breakpoints.between(NAVBAR.MOBLIE_MENU_WIDTH, NAVBAR.ICON_ONLY_WIDTH),
    { noSsr: true }
  );
  const isNavbarMobile = useMediaQuery(
    theme.breakpoints.down(NAVBAR.MOBLIE_MENU_WIDTH),
    { noSsr: true }
  );

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleNavbarDrawerClose = () => {
    setOpen(false);
  };

  const goToOtherPage = (url: string) => {
    history.push(url);
    handleNavbarDrawerClose();
  };

  const renderRadarListItem = () => {
    return (
      <SubscriptionFeature
        isMobile={isNavbarMobile}
        subscription={FEATURE_CODE.RADAR_COUNT}
        access={
          <>
            <ListItem
              button
              onClick={() => {
                setDataTracking({
                  targetAttribute: 'navbar-radar',
                  atlastracking: {
                    uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
                    uiElement: UI_ELEMENT.NAVBAR_MYCONTENT,
                    objectName: OBJECT_NAME.RADAR,
                  },
                });

                setTimeout(() => {
                  btnRef.current.click();
                  goToOtherPage(SITE_URLS.RADAR);
                }, 100);
              }}
            >
              {intl.formatMessage(messages.radar)}
            </ListItem>
          </>
        }
        deny={<></>}
      />
    );
  };

  const renderSaveItemListItem = () => {
    return (
      <ListItem
        button
        onClick={() => {
          setDataTracking({
            targetAttribute: 'navbar-savedItems',
            atlastracking: {
              uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
              uiElement: UI_ELEMENT.NAVBAR_MYCONTENT,
              objectName: OBJECT_NAME.SAVED_ITEMS,
            },
          });
          setTimeout(() => {
            btnRef.current.click();
            goToOtherPage(SITE_URLS.SAVED);
          }, 100);
        }}
      >
        {intl.formatMessage(messages.savedItems)}
      </ListItem>
    );
  };

  const renderEmailAlertsListItem = () => {
    return (
      <SubscriptionFeature
        isMobile={isNavbarMobile}
        subscription={FEATURE_CODE.HIDE_ARTICLE_TOOLBAR_RIGHTTABS}
        access={<></>}
        deny={
          <ListItem
            button
            onClick={() => {
              setDataTracking({
                targetAttribute: 'navbar-emailAlerts',
                atlastracking: {
                  uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
                  uiElement: UI_ELEMENT.NAVBAR_MYCONTENT,
                  objectName: OBJECT_NAME.EMAIL_ALERTS,
                },
              });

              setTimeout(() => {
                btnRef.current.click();
                goToOtherPage(SITE_URLS.EMAIL_ALERTS);
              }, 100);
            }}
          >
            {intl.formatMessage(messages.emailAlerts)}
          </ListItem>
        }
      />
    );
  };

  const renderDropDownListItem = () => {
    return (
      <List>
        {renderRadarListItem()}
        {renderSaveItemListItem()}
        {renderEmailAlertsListItem()}
        <button
          style={{ display: 'none' }}
          ref={btnRef}
          {...(dataTracking ? AtlasDataTrackable(dataTracking) : {})}
        ></button>
      </List>
    );
  };

  const renderDropDown = () => {
    return (
      <>
        <Popper
          open={open}
          className={classes.popper}
          anchorEl={anchorRef.current}
          placement="bottom-end"
          transition
          disablePortal
          style={{ zIndex: -2 }}
        >
          {({ TransitionProps }) => (
            <Slide
              direction="down"
              {...TransitionProps}
              timeout={300}
            >
              <Paper square>
                <ClickAwayListener onClickAway={handleClose}>
                  {renderDropDownListItem()}
                </ClickAwayListener>
              </Paper>
            </Slide>
          )}
        </Popper>
      </>
    );
  };

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Box className={classNames(isOnlyIcon && classes.onlyIconWrapper)}>
      <Button
        onClick={handleClick}
        className={classNames(classes.wrapper)}
        ref={anchorRef}
        {...AtlasDataTrackable({
          targetAttribute: 'my-content-dropdown-button',
          atlastracking: {
            uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
            uiElement: UI_ELEMENT.NAVBAR_MYCONTENT,
            objectName: OBJECT_NAME.OPEN_DROPDOWN,
          },
        })}
      >
        {isOnlyIcon ? (
          <BookOutlineIcon />
        ) : (
          <>
            <BookOutlineIcon />
            <Badge invisible={true} variant="dot">
              {intl.formatMessage(messages.myContent)}
            </Badge>
          </>
        )}
      </Button>
      {renderDropDown()}
    </Box>
  );
};

export default MyContentButtonDropDownMenu;

import { APPBAR } from '@constants/style';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  tab: {
    height: `${APPBAR.HEIGHT}px`,
  },
  alignRight: {
    textAlign: 'right',
    marginRight: '20px',
  },
  searchIconButton: {
    padding: 10,
  },
  dropdownMenuButton: {
    padding: 0,
    height: '60px',
  },
  dropdownMenuIcon: {
    width: '44px',
    height: '48px',
  },
  tabInMobile: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchIconHide: {
    display: 'none',
  },
}));
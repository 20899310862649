import React, { useContext } from 'react';
import { List, ListItem, Box, Typography, Popover } from '@mui/material';
import { useIntl } from 'react-intl';
import ConnectionPlusPlusIcon from '@react/components/icons/connectionPlusPlusIcon.component';
import {  FEATURE_CODE, OBJECT_NAME, UI_ELEMENT, UI_ELEMENT_GROUP } from '@constants/common';
import ConnectionPlusFeatureDialog from '@react/components/dialog/connectionPlusFeature/connectionPlusFeature.component';
import SubscriptionFeature from '@react/components/subscriptionFeature/subscriptionFeature.component';
import { FeatureCodeConst } from 'skyeye-common-const';
import { HsMenuListProfileMenuType } from '@react/components/menu/profile/menuListProfile.component';
import { ROUTE_PATHS, SITE_URLS } from '@constants/routes';
import { getFeatureRoles } from '@react/utils/featureToggle.util';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getIsPublicUser, getUserRole, getSubscriptionFeatureListWidthRemoveSeperator } from '@redux/selectors/account.selector';
import { setUserRole } from '@redux/actions/account.actions';
import { FeatureToggleContext } from '@react/components/featureToggle/featureToggleContext';
import classnames from 'classnames';
import { getSubcriptionFeatureReport } from '@react/utils/common.util';
import { useStyles } from './style';
import { IRightDrawerActionProps } from './rightDraweAction.props';

export interface IHsRightDrawerNavbarProps extends IRightDrawerActionProps {
  onButtonConnectionPlusClick: () => void,
  AtlasDataTrackable: any,
  showDialog: boolean,
  hideDialog: ()=>void,
  handleOnDoNotShowThisAgainChange: (checked: boolean) => void,
  onButtonTryFeatureNowClick: () => void,
  goToScoutAI: (event: any) => void,
  goToOtherPage: (url: string) => void,
  onSavedSearchesClick?: () => void,
  onTargetsClick?: () => void,
  messages: any,
  navbarAnchor?: any,
  onProfileAvatarMenuItemSelect: (type: HsMenuListProfileMenuType)=> void,
}

function HsRightDrawerNavbar(props: IHsRightDrawerNavbarProps) {
  const {
    open, onClose, onButtonConnectionPlusClick, AtlasDataTrackable,
    showDialog, hideDialog, handleOnDoNotShowThisAgainChange, onButtonTryFeatureNowClick,
    goToScoutAI, goToOtherPage, messages,
    onProfileAvatarMenuItemSelect, navbarAnchor
  } = props;
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();

  const isPublicUser: boolean = useSelector(getIsPublicUser);
  const reduxDispatch = useDispatch();
  const activeUserRole = useSelector(getUserRole);
  const subcriptionFeaturePermissionModel = useSelector(getSubscriptionFeatureListWidthRemoveSeperator);
  const allReport = getSubcriptionFeatureReport(subcriptionFeaturePermissionModel);
  const { featureJSON } = useContext(FeatureToggleContext);
  const featureRoles = getFeatureRoles(featureJSON, activeUserRole);

  const companyQuery = {
    data: {
      searchStr: '',
    },
  };

  const goToSearchArticleWithReport = () => {
    const queryObj = {
      newsQuery: {
        data: {
          searchStr: '',
          publishers: allReport,
        }
      },
      companyQuery
    };
  
    history.push(ROUTE_PATHS.SEARCH_ARTICLE, { ...queryObj });
    onClose && onClose();
  };

  const goToSearch = (url: string) => {
    const queryObj = {
      newsQuery: {
        data: {
          searchStr: '',
        },
      },
      companyQuery
    };

    history.push(url, { ...queryObj });
    onClose && onClose();
  };

  const goToOverview = () => {
    history.push(ROUTE_PATHS.CMS_OVERVIEW);
  };

  const renderUserProfileItem = (icon, label, profileMenuType, objectName) => {
    return (
      <ListItem
        button
        onClick={() => {
          onProfileAvatarMenuItemSelect(profileMenuType);
          onClose && onClose();
        }}
        {...AtlasDataTrackable({ targetAttribute: `mobile-navbar-${label}`, atlastracking: { uiElementGroup: UI_ELEMENT_GROUP.NAVBAR, uiElement: UI_ELEMENT.NAVBAR, objectName } })}
      >
        {icon}
        <Typography
          className={`${(profileMenuType === HsMenuListProfileMenuType.upgrade) || classes.menuNoMargin}`}
        >
          {label}
        </Typography>
      </ListItem>
    );
  };

  return (
    <React.Fragment>
      <Popover
        id='Navbar-Right-Drawer'
        open={open}
        anchorEl={navbarAnchor}
        className={classes.drawerNavbarRoot}
        disablePortal
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <>
          <Box role="presentation" className={classes.navbarRoot}>
            <List className={classes.navbarList}>
              <ListItem
                button
                className={classes.navbarListItem}
                onClick={() => goToSearch(ROUTE_PATHS.SEARCH_ARTICLE)}
                {...AtlasDataTrackable({
                  targetAttribute: 'news-button',
                  atlastracking: {
                    uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
                    uiElement: UI_ELEMENT.NAVBAR_NEWS,
                    objectName: OBJECT_NAME.NEWSSEARCH_RESULT,
                  },
                })}
              >
                <Typography>{intl.formatMessage(messages.news)}</Typography>
              </ListItem>
              <ListItem
                button
                className={classes.navbarListItem}
                onClick={() => goToSearch(ROUTE_PATHS.SEARCH_COMPANY)}
                {...AtlasDataTrackable({
                  targetAttribute: 'news-button',
                  atlastracking: {
                    uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
                    uiElement: UI_ELEMENT.NAVBAR_COMPANIES,
                    objectName: OBJECT_NAME.COMPANYSEARCH_RESULT,
                  },
                })}
              >
                <Typography>{intl.formatMessage(messages.companies)}</Typography>
              </ListItem>
              <ListItem
                button
                className={classes.navbarListItem}
                onClick={goToSearchArticleWithReport}
                {...AtlasDataTrackable({
                  targetAttribute: 'news-button',
                  atlastracking: {
                    uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
                    uiElement: UI_ELEMENT.NAVBAR_REPORTS,
                    objectName: OBJECT_NAME.REPORTSEARCH_RESULT,
                  },
                })}
              >
                <Typography>{intl.formatMessage(messages.reports)}</Typography>
              </ListItem>
              <SubscriptionFeature
                subscription={FeatureCodeConst.CONNECTION_PLUS_CREDIT}
                access={
                  <ListItem
                    button
                    className={classes.navbarListItem}
                    onClick={() => {
                      onButtonConnectionPlusClick();
                      onClose && onClose();
                    }}
                    {...AtlasDataTrackable({ targetAttribute: 'mobile-navbar-connection-plus', atlastracking: { uiElementGroup:UI_ELEMENT_GROUP.NAVBAR, uiElement:UI_ELEMENT.NAVBAR, objectName:OBJECT_NAME.CONNECTIONS, objectNameDetails:OBJECT_NAME.CONNECTIONS } })}
                  >
                    <Typography>
                      {intl.formatMessage(messages.bannerTitle)}
                      <ConnectionPlusPlusIcon
                        className={classes.iconConnectionPlus}
                      />
                    </Typography>
                  </ListItem>
                }
                deny={<></>}
              />
              <ListItem
                button
                className={classes.navbarListItem}
                onClick={() => {
                  goToOverview();
                  onClose && onClose();
                }}
                {...AtlasDataTrackable({
                  targetAttribute: 'news-button',
                  atlastracking: {
                    uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
                    uiElement: UI_ELEMENT.NAVBAR_MACRO,
                    objectName: OBJECT_NAME.GO_MACRO,
                  },
                })}
              >
                <Typography>
                  {intl.formatMessage(messages.macroOverview)}
                </Typography>
              </ListItem>
              <div className={classes.divider} />
              <SubscriptionFeature
                subscription={FEATURE_CODE.RADAR_COUNT}
                access={
                  <>
                    <ListItem
                      button
                      className={classnames(classes.navbarListItem)}
                      onClick={() => (goToOtherPage(SITE_URLS.RADAR))}
                      
                      {...AtlasDataTrackable({ targetAttribute: 'mobile-navbar-radar', atlastracking: { uiElementGroup:UI_ELEMENT_GROUP.NAVBAR, uiElement:UI_ELEMENT.NAVBAR, objectName:OBJECT_NAME.RADAR } })}
                    >
                      <Typography>{intl.formatMessage(messages.radar)}</Typography>
                    </ListItem>
                  </>
            
                }
                deny={<></>}
              />
              <ListItem
                button
                className={classes.navbarListItem}
                onClick={() => goToOtherPage(SITE_URLS.SAVED)}
                {...AtlasDataTrackable({
                  targetAttribute: 'mobile-navbar-bookmark',
                  atlastracking: {
                    uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
                    uiElement: UI_ELEMENT.NAVBAR,
                    objectName: OBJECT_NAME.BOOKMARK,
                  },
                })}
              >
                <Typography>
                  {intl.formatMessage(messages.saveItems)}
                </Typography>
              </ListItem>
              <SubscriptionFeature
                subscription={FEATURE_CODE.HIDE_ARTICLE_TOOLBAR_RIGHTTABS}
                access={<></>}
                deny={
                  <ListItem
                    button
                    onClick={goToScoutAI}
                    className={classes.navbarListItem}
                    {...AtlasDataTrackable({ targetAttribute: 'mobile-navbar-alertSettings', atlastracking: { uiElementGroup:UI_ELEMENT_GROUP.NAVBAR, uiElement:UI_ELEMENT.NAVBAR, objectName:OBJECT_NAME.ALERTSETTINGS } })}
                  >
                    <Typography>{intl.formatMessage(messages.emailAlerts)}</Typography>
                  </ListItem>
                }
              />
              <div className={classes.divider} />
              <SubscriptionFeature
                subscription={FEATURE_CODE.HIDE_ARTICLE_TOOLBAR_AVATAR}
                access={
                  <ListItem
                    button
                    className={classnames(classes.navbarListItem)}
                    onClick={() => {
                      onProfileAvatarMenuItemSelect(HsMenuListProfileMenuType.upgrade);
                      onClose && onClose();
                    }}
                    {...AtlasDataTrackable({
                      targetAttribute: `mobile-navbar-${intl.formatMessage(messages.myAccount)}`,
                      atlastracking: { uiElementGroup: UI_ELEMENT_GROUP.NAVBAR, uiElement: UI_ELEMENT.NAVBAR, objectName: OBJECT_NAME.UPGRADE }
                    })}
                  >
                    <Typography>
                      { intl.formatMessage(messages.upgrade)}
                    </Typography>
                  </ListItem>
                }
                deny={
                  <ListItem
                    button
                    className={classnames(classes.navbarListItem)}
                    onClick={() => {
                      onProfileAvatarMenuItemSelect(HsMenuListProfileMenuType.myAccount);
                      onClose && onClose();
                    }}
                    {...AtlasDataTrackable({
                      targetAttribute: `mobile-navbar-${intl.formatMessage(messages.myAccount)}`,
                      atlastracking: { uiElementGroup: UI_ELEMENT_GROUP.NAVBAR, uiElement: UI_ELEMENT.NAVBAR, objectName: OBJECT_NAME.ACCOUNT }
                    })}
                  >
                    <Typography>
                      { intl.formatMessage(messages.myAccount)}
                    </Typography>
                  </ListItem>
                }
              />
              {!isPublicUser && featureRoles.map(role => {
                return (
                  <ListItem
                    button
                    className={classes.navbarListItem}
                    key={role}
                    onClick={() => {
                      reduxDispatch(setUserRole(role)); 
                    }}
                    {...AtlasDataTrackable({ targetAttribute: 'mobile-navbar-savedSearches', atlastracking: { uiElementGroup:UI_ELEMENT_GROUP.NAVBAR, uiElement:UI_ELEMENT.NAVBAR, objectName:OBJECT_NAME.SAVEDSEARCHES } })}
                  >
                    <Typography>{intl.formatMessage(messages.changeRole, { role })}</Typography>
                  </ListItem>
                );
              })}
              <ListItem
                button
                className={classes.navbarListItem}
                onClick={() => {
                  onProfileAvatarMenuItemSelect(HsMenuListProfileMenuType.faqs);
                  onClose && onClose();
                }}
                {...AtlasDataTrackable({
                  targetAttribute: `mobile-navbar-${intl.formatMessage(messages.faqs)}`,
                  atlastracking: { uiElementGroup: UI_ELEMENT_GROUP.NAVBAR, uiElement: UI_ELEMENT.NAVBAR, objectName: OBJECT_NAME.FAQ }
                })}
              >
                <Typography>
                  { intl.formatMessage(messages.faqs)}
                </Typography>
              </ListItem>
              <ListItem
                button
                className={classes.navbarListItem}
                onClick={() => {
                  onProfileAvatarMenuItemSelect(HsMenuListProfileMenuType.logout);
                  onClose && onClose();
                }}
                {...AtlasDataTrackable({
                  targetAttribute: `mobile-navbar-${intl.formatMessage(messages.logout)}`,
                  atlastracking: { uiElementGroup: UI_ELEMENT_GROUP.NAVBAR, uiElement: UI_ELEMENT.NAVBAR, objectName: OBJECT_NAME.LOGOUT }
                })}
              >
                <Typography>
                  { intl.formatMessage(messages.logout)}
                </Typography>
              </ListItem>
            </List>
          </Box>
        </>
      </Popover>
      <ConnectionPlusFeatureDialog
        open={showDialog}
        onClose={hideDialog}
        onDoNotShowThisAgainChange={handleOnDoNotShowThisAgainChange}
        onTryFeatureNowClick={onButtonTryFeatureNowClick}
      />
    </React.Fragment>
  );
}

export default HsRightDrawerNavbar;

import React, { Fragment, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Popper, ClickAwayListener, Slide, Paper } from '@mui/material';
import HsMenuListProfile, {
  HsMenuListProfileMenuType,
} from '../menu/profile/menuListProfile.component';
import AccountButton from '../button/account/toolbarAccountButton.component';

const useStyles = makeStyles(() =>
  createStyles({
    popper: {
      zIndex: -2,
    },
    menuProfile: {
      width: '175px',
      borderRadius: 0,
    },
  })
);

export type IHsToolbarProfileAvatarProps = {
  className?: string;
  onMenuItemSelect?: (type: HsMenuListProfileMenuType) => void;
};

function HsToolbarProfileAvatar(props: IHsToolbarProfileAvatarProps) {
  const { onMenuItemSelect } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <AccountButton onClick={handleToggle} />
          <Popper
            open={open}
            className={classes.popper}
            anchorEl={anchorEl}
            role={undefined}
            placement="bottom-end"
            transition
            disablePortal
          >
            {({ TransitionProps }) => (
              <Slide direction="down" {...TransitionProps} timeout={300}>
                <Paper square>
                  <HsMenuListProfile
                    id="menu-profile"
                    autoFocusItem={open}
                    className={classes.menuProfile}
                    onKeyDown={handleListKeyDown}
                    onCloseDropdown={()=>{ handleClose(); }}
                    onItemClick={(type) => {
                      onMenuItemSelect && onMenuItemSelect(type);
                    }}
                  />
                </Paper>
              </Slide>
            )}
          </Popper>
        </div>
      </ClickAwayListener>
    </Fragment>
  );
}

HsToolbarProfileAvatar.defaultProps = {
  userName: '',
  className: '',
} as Partial<IHsToolbarProfileAvatarProps>;

export default HsToolbarProfileAvatar;

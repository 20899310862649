import { defineMessages } from 'react-intl';

export const scope = 'src.react.components.toolbar';

export default defineMessages({
  countriesRegions: {
    id: `${scope}.countriesRegions`,
    defaultMessage: 'Countries & regions',
  },
  login: {
    id: `${scope}.login`,
    defaultMessage: 'Login',
  },
  savedSearches: {
    id: `${scope}.savedSearches`,
    defaultMessage: 'Saved Searches',
  },
  sectors: {
    id: `${scope}.sectors`,
    defaultMessage: 'Sectors',
  },
  alertSettings: {
    id: `${scope}.alertSettings`,
    defaultMessage: 'Alert Settings',
  },
  targets: {
    id: `${scope}.targets`,
    defaultMessage: 'Targets',
  },
  bannerTitle: {
    id: `${scope}.bannerTitle`,
    defaultMessage: 'Connections',
  },
  library: {
    id: `${scope}.library`,
    defaultMessage: 'Library',
  },
  radar: {
    id: `${scope}.radar`,
    defaultMessage: 'Radar',
  },
  alerts: {
    id: `${scope}.alerts`,
    defaultMessage: 'Alerts',
  },
  myAccount: {
    id: `${scope}.myAccount`,
    defaultMessage: 'My Account',
  },
  faqs: {
    id: `${scope}.faqs`,
    defaultMessage: 'FAQs',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Logout',
  },
  upgrade: {
    id: `${scope}.upgrade`,
    defaultMessage: 'Upgrade',
  },

  newsLists: {
    id: `${scope}.newsLists`,
    defaultMessage: 'News Lists',
  },
  targetLists: {
    id: `${scope}.targetLists`,
    defaultMessage: 'Target Lists',
  },
  emailAlerts: {
    id: `${scope}.emailAlerts`,
    defaultMessage: 'Email Alerts',  
  },
  saveItems: {
    id: `${scope}.saveItems`,
    defaultMessage: 'Saved Items',  
  },
  companyLists: {
    id: `${scope}.companyLists`,
    defaultMessage: 'Company Lists',     
  },
  newsFilters: {
    id: `${scope}.newsFilters`,
    defaultMessage: 'News Filters',     
  },
  companyFilters: {
    id: `${scope}.companyFilters`,
    defaultMessage: 'Company Filters',     
  },
  changeRole: {
    id: `${scope}.role`,
    defaultMessage: 'Change to {role}',
  },
  connections: {
    id: `${scope}.connections`,
    defaultMessage: 'Connections+', 
  },
  reports: {
    id: `${scope}.reports`,
    defaultMessage: 'Reports', 
  },
  myContent: {
    id: `${scope}.myContent`,
    defaultMessage: 'My Content', 
  },
  news: {
    id: `${scope}.news`,
    defaultMessage: 'News', 
  },
  companies: {
    id: `${scope}.companies`,
    defaultMessage: 'Companies',
  },
  macroOverview: {
    id: `${scope}.overview`,
    defaultMessage: 'Macro Statistics',
  }
});
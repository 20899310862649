import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '../props/common/svgicon.props';

const DropdownMenuIcon = (props: ISvgIconProps) => {
  const { className } = props;
  const svg = (
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M0 0h44v48H0z" />
      <g fill="#404040">
        <path d="M14 19h16v2H14zM14 24h16v2H14zM14 29h16v2H14z" />
      </g>
    </g>
  );

  return (
    <SvgIcon viewBox="0 0 44 48" className={className}>
      {svg}
    </SvgIcon>
  );
};

export default DropdownMenuIcon;
import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Button, useMediaQuery } from '@mui/material';
import { NAVBAR } from '@constants/style';
import { useIntl } from 'react-intl';
import AccountIcon from '@react/components/icons/account.component';
import { OBJECT_NAME, UI_ELEMENT, UI_ELEMENT_GROUP } from '@constants/common';
import messages from './messages';
import AtlasDataTrackable from '../../atlas/atlas.component';

type IProps = {
  className?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    button: (props: { isOnlyIcon: boolean }) => ({
      textTransform: 'none',
      color: '#404040',
      borderLeft: '1px solid #e2e2e2',
      fontSize: '16px',
      height: '60px',
      width: props.isOnlyIcon ? '73px' : '175px',
      lineHeight: '60px',
      borderRadius: 0,
    }),
  })
);

function AccountButton(props: IProps) {
  const { className, onClick } = props;
  const intl = useIntl();
  const theme = useTheme();
  const isOnlyIcon = useMediaQuery(
    theme.breakpoints.between(NAVBAR.MOBLIE_MENU_WIDTH, NAVBAR.ICON_ONLY_WIDTH),
    { noSsr: true }
  );
  const classes = useStyles({ isOnlyIcon });

  const isNavbarMobile = useMediaQuery(
    theme.breakpoints.down(NAVBAR.MOBLIE_MENU_WIDTH),
    { noSsr: true }
  );

  const dataTrackable = {
    targetAttribute: 'account-dropdown-toggle',
    atlastracking: {
      uiElementGroup: UI_ELEMENT_GROUP.NAVBAR,
      uiElement: UI_ELEMENT.NAVBAR_ACCOUNT,
      objectName: OBJECT_NAME.OPEN_DROPDOWN,
    },
  };

  if (isNavbarMobile) return <></>;

  return (
    <div className={`${classes.root} ${className}`}>
      <Button
        className={classes.button}
        onClick={onClick}
        {...{ ...AtlasDataTrackable(dataTrackable) }}
      >
        <AccountIcon />
        {!isOnlyIcon && intl.formatMessage(messages.account)}
      </Button>
    </div>
  );
}

export default AccountButton;

import makeStyles from '@mui/styles/makeStyles';
import { PAGE, APPBAR, CONTENT_WIDTH, ANNOUNCEMENT, ZINDEX, CONTENT_PADDING_HORIZONTAL } from '@constants/style';

interface IStyleProps {
  hasAnnouncement?: boolean,
  fixedAnnouncement?: boolean,
  hideMenu?: boolean,
  isMobile?: boolean,
}

export const useStyles = makeStyles((theme) => ({
  acceptTermPageRoot: {
    backgroundColor: theme.palette.background.default,
  },
  acceptTermContentRoot: {
    width: '100%',
    position: 'relative',
    padding: '0px 20px',
    [`@media (min-width: ${CONTENT_WIDTH + CONTENT_PADDING_HORIZONTAL}px)`]: {
      width: `${CONTENT_WIDTH + CONTENT_PADDING_HORIZONTAL}px`,
    },
  },
  contentRootBase: (props: IStyleProps) => ({
    position: 'relative',
    paddingTop: `${APPBAR.HEIGHT}px`,
    backgroundColor: PAGE.BG,
    minHeight: `calc(100vh - ${props.hideMenu ? props.isMobile ? 114 : APPBAR.HEIGHT + 1 : props.hasAnnouncement && !props.fixedAnnouncement ? ANNOUNCEMENT.HEIGHT : 0}px)`,
  }),
  contentRoot: {
    width: '100%',
    [`@media (min-width: ${CONTENT_WIDTH + CONTENT_PADDING_HORIZONTAL}px)`]: {
      width: `${CONTENT_WIDTH + CONTENT_PADDING_HORIZONTAL + 60}px`,
    },
  },
  contentFullWidthRoot: {
    width: '100%',
  },
  contentRootFixed: (props: IStyleProps) => ({
    height: `calc(100vh - ${props.hasAnnouncement ? ANNOUNCEMENT.HEIGHT : 0}px)`,
  }),
  contentRootNonResponsive: {
    flexGrow: 1,
  },
  contentBodyFullWidth: {
    padding: '0px',
  },
  contentBody: {
    padding: '0',
  },
  contentBodyFixed: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
  },
  announcementRoot: {
    zIndex: ZINDEX.ANNOUNCEMENT,
    marginTop: `${APPBAR.HEIGHT}px`,
    '& > div': {
      width: '100%',
      [`@media (min-width: ${CONTENT_WIDTH + CONTENT_PADDING_HORIZONTAL}px)`]: {
        marginLeft: 0,
        width: `${CONTENT_WIDTH + CONTENT_PADDING_HORIZONTAL}px`,
      },
    },
    [`@media (min-width: ${CONTENT_WIDTH + CONTENT_PADDING_HORIZONTAL}px)`]: {
      justifyContent: 'center',
    },
  },
  announcementRootFixed: {
    marginTop: 'auto',
    position: 'fixed',
    top: `${APPBAR.HEIGHT}px`,
  },
  announcementSpace: {
    width: '100%',
    height: ANNOUNCEMENT.HEIGHT,
  },
}));
